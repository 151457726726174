export const ClarityUserProfileTagHandler = (ltUser, user) => {
  if (!window.clarity) return false;

  const claritySet = (key, value) => {
    window.clarity("set", key, value);
  };

  const clarityIdentify = (email, id, name) => {
    window.clarity("identify", email, id ? `LT-UID-${id}` : "", "", name);
  };

  if (ltUser?.email) {
    claritySet("LTUserEmail", ltUser.email);
    if (user?.email) {
      clarityIdentify(user.email, user.id, user.name);
    }
  } else if (user?.email) {
    claritySet("userEmail", user.email);
    clarityIdentify(user.email, user.id, user.name);
  }

  return true;
};
